.__json-pretty__ {
	line-height: 1.3;
	color: #0095da;
	background: white;
	overflow: auto;
}

.__json-pretty__ .__json-key__ {
	color: #46C805FF;
}

.__json-pretty__ .__json-value__ {
	color: deepskyblue;
}

.__json-pretty__ .__json-string__ {
	color: purple;
}

.__json-pretty__ .__json-boolean__ {
	color: #448aa9
}

.__json-pretty-error__ {
	line-height: 1.3;
	color: #748096;
	background: #1e1e1e;
	overflow: auto
}
