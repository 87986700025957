svg > g > g:last-child {
	pointer-events: none
}

iframe {
	border: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}