$toast_info: #3598db;
$toast_success: #00bc8c;
$toast_danger: #e84c3d;
$toast_warning: #f39c11;

$hiber_blue: rgb(17, 35, 99);

.toastify {
	& > div {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
		border-radius: 10px;
		opacity: 0.95;
		padding: 8px 12px 8px 8px;
		color: white;
		cursor: default;
		&:hover {
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
			opacity: 1;
		}
	}
	& .info {
		background: $toast_info;
		.c-link-button {
			.btn-link {
				color: $hiber_blue;
				border-bottom-color: $hiber_blue;
			}
		}
	}
	& .success {
		background: $toast_success;
		.c-link-button {
			.btn-link {
				color: $hiber_blue;
				border-bottom-color: $hiber_blue;
			}
		}
	}
	& .danger {
		background: $toast_danger;
		.c-link-button {
			.btn-link {
				color: white;
				border-bottom-color: white;
			}
		}
	}
	& .warning {
		background: $toast_warning;
		.c-link-button {
			.btn-link {
				color: $hiber_blue;
				border-bottom-color: $hiber_blue;
			}
		}
	}
	button:focus {
		outline: none !important;
	}
}