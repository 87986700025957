.fab-container {
	display: flex;
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 0;
	position: fixed;
	right: 3em;
	bottom: 3em;

	&.actionTop {
		flex-direction: column-reverse;
		.fab-action {
			.tooltip {
				left: -12px;
			}
			.label {
				right: 54px;
			}
		}
	}
	
	&.actionLeft {
		flex-direction: row-reverse;
		.fab-action {
			.tooltip {
				top: -22px;
				left: 30px
			}

			.label { 
				top: -26px;
				left: 0;
				right: 0;
			}
		}
	}
	
	&.open {
		max-height: max-content;
	}

	li {
		border-radius: 50%;
		background-color: #CCC;
		box-shadow: 0 3px 6px lightgrey;
		display: grid;
		place-items: center;
		margin: 4px;
		font-size: 28px;
		padding: 12px;
		cursor: pointer;
		position: relative;
		
		&.fab-button {
			padding: 16px;
		}
		
		&.disabled {
			cursor: auto;
		}
	}

	.fab-button {
		background-color: #00a8ff;

		&.open {
			background-color: red;
		}

		svg {
			fill: white;
		}
	}

	.fab-action {
		transform: translateY(50px) scale(0);
		transition: transform 300ms, opacity 300ms;
		opacity: 0;

		&:hover {
			.tooltip {
				transform: translateX(-100%) scale(1);
				opacity: 1;
			}
		}

		&.open {
			transform: translateY(0) scale(1);
			opacity: 1;
		}

		&.disabled {
			opacity: 0.7;
		}

		.tooltip {
			padding: 4px 6px;
			font-size: 12px;
			position: absolute;
			background-color: #353b48;
			border-radius: 4px;
			color: white;
			opacity: 0;
			transition: transform 300ms, opacity 300ms;
			user-select: none;
		}

		.label {
			padding: 4px 6px;
			font-size: 12px;
			position: absolute;
			background-color: #353b48;
			border-radius: 4px;
			color: white;
			opacity: 1;
			user-select: none;
			min-width: max-content;
		}
	}
}
